import React from "react";
import cartLogo from "../../../../assets/icons/cart-logo.svg";

import "./SaleButton.scss";

export const SaleButton = () => {
  return (
    <button className="action-button success flex center">
      <img src={cartLogo} alt="cart-logo"/>
      <p className="action-button-text bold">Private sale</p>
    </button>
  );
};
