import React, { memo } from "react";
import ReactModal from "react-modal";
import close from "../../assets/icons/close-icon.svg";
import success from "../../assets/icons/success-icon.svg";
import "./Modal.scss";


// Здесь пришлось отказаться от объекта со стилями, т.к. 
// они приписываются инлайново в style, а там не поддерживаются медиа-запросы

export const Modal = memo(({ showModal, handleCloseModal }) => {
  return (
    <ReactModal
      isOpen={showModal}
      onRequestClose={handleCloseModal}
      contentLabel="Modal Fill with my Multipass"
      className="modal-content relative"
      overlayClassName="modal-ovelay "
    >
      <div className="flex center h-100">
        <div className="flex center column h-100">
          <div className="modal-close-wrap flex end">
            <div className="flex end">
              <button
                onClick={handleCloseModal}
                type="button"
                className="modal-close-icon"
              >
                <img src={close} alt="close" />
              </button>
            </div>
          </div>
          <img className="modal-success-icon" src={success} alt="success" />
          <p className="modal-description">
            Thanks! We will send you an invitation on the opening day.
          </p>
          <button
            onClick={handleCloseModal}
            type="button"
            className="modal-submit-button"
          >
            OK
          </button>
        </div>
      </div>
    </ReactModal>
  );
});
