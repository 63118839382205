import React, { useState } from "react";
import axios from "axios";
import { Modal } from "../../../../components/Modal/Modal";
import "./EmaiForm.scss";

export const EmaiForm = () => {
  const [data, setData] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    const obj = {
      to: "pre-sale@alium.finance",
      subject: "New client email",
      message: data,
    };
    setLoading(true);
    axios
      .post("/api/send-email/", obj)
      .then(() => {
        setIsOpen(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    setData(e.target.value);
  };

  const handleClose = () => {
    setIsOpen(false);
    setData("");
  };

  return (
    <div className="form-wrap">
      <Modal showModal={isOpen} handleCloseModal={handleClose} />
      <form onSubmit={handleSubmit} className="form flex">
        <input
          name="email"
          type="email"
          onChange={handleChange}
          value={data}
          placeholder="Enter your email"
          className="form-input"
          required
        />
        <button className="form-button" type="submit">
          {loading && (
            <i
              className="fa fa-refresh fa-spin"
              style={{ marginRight: "5px" }}
            />
          )}
          Inform about Launch
        </button>
      </form>
    </div>
  );
};
