import React from "react";
import { Timer } from "../../../../components/Timer/Timer";
import "./Countdown.scss";

export const Countdown = () => {
  return (
    <div className="countdown-wrap">
      <div className="countdown">
        <p className="countdown-heading">Left before the start:</p>
        <Timer
          hideYear
          hideMonth
          // hideDay
          // hideHour
          // hideMinute
          hideSecond
          size="small"
          theme="light"
          titlePosition="bottom"
          endAt={"2021-03-14 00:00:00"} // Date/Time
        />
      </div>
    </div>
  );
};
