import React from "react";
import first from "../../assets/icons/ghub.svg";
import second from "../../assets/icons/tel.svg";
import third from "../../assets/icons/twi.svg";
import fourth from "../../assets/icons/med.svg";
import fifth from "../../assets/icons/glab.svg";
import "./SocialNetworks.scss";

const header = [
  {
    img: first,
    link: "https://github.com/Aliumswap",
  },
  {
    img: second,
    link: "https://t.me/aliumswap_official",
  },
  {
    img: third,
    link: "https://twitter.com/AliumSwap",
  },
  {
    img: fourth,
    link: "https://medium.com/@aliumswap",
  },
  {
    img: fifth,
    link: "https://about.gitlab.com/",
  },
].map((item, i) => ({ ...item, id: i }));

export const SocialNetworks = () => {
  const handleClick = (e) => {
    openInNewTab(e.currentTarget.name);
  };

  const openInNewTab = (url) => {
    if (!url) return;
    const win = window.open(header[url].link, "_blank");
    win.focus();
  };

  return (
    <div className="header-links flex justify-between align-center">
      {header.map((item) => (
        <button
          key={item.id}
          name={item.id}
          className="header-links-item flex center"
          onClick={handleClick}
        >
          <img className="header-links-item__img" src={item.img} alt="img" />
        </button>
      ))}
    </div>
  );
};
