import React from "react";
import logo from "../../assets/icons/logo.svg";
import logoMobile from "../../assets/icons/logo-mobile.svg";
import { SocialNetworks } from "../../components/SocialNetworks/SocialNetworks";
import { Countdown } from "./components/Countdown/Countdown";
import { EmaiForm } from "./components/EmaiForm/EmaiForm";
import { Partners } from "./components/Partners/Partners";
import { SaleButton } from "./components/SaleButton/SaleButton";

import heroImg from "../../assets/images/hero-image.png";
import "./Main.scss";

export const Main = () => {
  return (
    <div>
      <header className="header flex align-center justify-between w-100">
        <div className="flex center">
          <img className="main-header-logo logo__desktop" src={logo} alt="logo" />
          <SaleButton />
        </div>
        <SocialNetworks />
        <img className="main-header-logo logo__mobile" src={logoMobile} alt="logo" />
      </header>

      <div className="main-wrap flex justify-between">
        <div className="main">

          <div className="main-img-wrap">
            <img className="main-img__768" src={heroImg} alt="img" />
            <img className="main-img__375" src={heroImg} alt="img" />
          </div>
          <div className="main-content">
            <h1 className="main-heading main-container">
              Multi-Blockchain Decentralized AMM Exchange with NFT’s
          </h1>
            <h2 className="main-description description-text main-container">
            Unlimited BSC AliumSwap V1 with pancake swap Liquidity Migration Option
          </h2>
            <div className="main-container">
              <Countdown />
              <EmaiForm />
            </div>
            <Partners />
          </div>
        </div>
        <div className="main-img-wrap">
          <img className="main-img" src={heroImg} alt="img" />
        </div>
      </div>
    </div>

  );
};
