import React from "react";
import binanceLogo from "../../../../assets/icons/binance-logo.svg";
import hecoLogo from "../../../../assets/icons/heco-logo.svg";
import nearLogo from "../../../../assets/icons/near-logo.svg";
import maticLogo from "../../../../assets/icons/matic-logo.svg";
import avalancheLogo from "../../../../assets/icons/avalanche-logo.png";
import eth from "../../../../assets/icons/ethereum.svg";
import "./Partners.scss";

export const Partners = () => {
  return (
    <div className="partners">
      <p className="partners-description description-text">
        Will be available on:
      </p>
      <div className="partners-logos flex justify-between">
        <img
          className="partners-logos-item"
          src={binanceLogo}
          alt="binance logo"
        />
        <img className="partners-logos-item" src={hecoLogo} alt="heco-logo" />
        <img className="partners-logos-item" src={nearLogo} alt="near-logo" />
        <img className="partners-logos-item" src={maticLogo} alt="matic-logo" />
        <img
          className="partners-logos-item partners-logos-item__last"
          src={avalancheLogo}
          width="100px"
          alt="matic-logo"
        />
        <img className="partners-logos-item" src={eth} alt="eth-logo" />
      </div>
    </div>
  );
};
